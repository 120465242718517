import React from 'react';
import { Svg } from 'src/components/Svg/Svg';
import { socialLinks } from 'src/config.json';
import { FooterAction } from 'src/components/Footer/FooterAction';
import classnames from 'classnames';

import classes from './FooterContact.scss';

export const FooterContact = () => (
    <div className={classes.container}>
        <ul className={classes.footerSocial}>
            <li>
                <a
                    className={classes.footerSocialLink}
                    href={socialLinks.facebook}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <Svg role="img" name="facebook" />
                </a>
            </li>
            <li className={classes.footerSocialItem}>
                <a
                    className={classes.footerSocialLink}
                    href={socialLinks.instagram}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <Svg role="img" name="instagram" />
                </a>
            </li>
            <li className={classes.footerSocialItem}>
                <a
                    className={classes.footerSocialLink}
                    href={socialLinks.linkedin}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <Svg role="img" name="linkedin" />
                </a>
            </li>
            <li className={classes.footerSocialItem}>
                <a
                    className={classes.footerSocialLink}
                    href={socialLinks.twitter}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <Svg role="img" name="twitter" />
                </a>
            </li>
            <li className={classes.footerSocialItem}>
                <a
                    className={classes.footerSocialLink}
                    href={socialLinks.youtube}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <Svg role="img" name="youtube" />
                </a>
            </li>
        </ul>

        <FooterAction className={classes.footerContactAction} />

        <p className={classes.footerCopyright}>© Workwear Express Ltd Company No. 3743499</p>

        <p className={classes.footerTerms}>
            <a className={classes.footerTerms} href="/terms-conditions">
                Terms & Conditions
            </a>
        </p>

        <div className={classes.footerPoliciesContainer}>
            <a className={classes.footerPolicy} href="/privacy-policy-and-cookies">
                Privacy Policy
            </a>
            <a className={classes.footerPolicy} href="/cookie-policy">
                Cookie Policy
            </a>
        </div>
    </div>
);
