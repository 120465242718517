import React from 'react';

import classes from './StaticBanner.scss';

export const StaticBanner = () => (
    <strong className={classes.staticBanner}>
        <div>
            <img src={require('../../../static/icons/guarantee.svg')} alt="guarantee icon" />
            <span>UK’s number one since 1990</span>
        </div>
        <div>
            <img src={require('../../../static/icons/free-delivery.svg')} alt="free delivery icon" />
            <span>Get free delivery on orders over £99 net!</span>
        </div>
    </strong>
);
