import React, { HTMLAttributes } from 'react';

import classes from './PageTitle.scss';

type Props = {
    title: string;
} & HTMLAttributes<any>;

export const PageTitle: React.FC<Props> = (props) => {
    const { title, ...rest } = props;
    return (
        <h1 className={classes.pageTitle} data-test-id="PageTitle" {...rest}>
            {title}
            {props.children}
        </h1>
    );
};
